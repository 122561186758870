<script lang="ts">
  import Notification from "./notification.svelte";
  import { dismissNotification, notifications } from "./store/notifications";
  import { flip } from "svelte/animate";
</script>

{#if $notifications}
  <section>
    <ul class="flex flex-col u-gap-16">
      {#each $notifications as notification (notification.id)}
        <li animate:flip={{ duration: 500 }}>
          <Notification
            type={notification.type}
            title={notification.title}
            icon={notification.icon}
            on:dismiss={() => dismissNotification(notification.id)}
            buttons={notification?.buttons}
          >
            {notification.message}

            {#if notification.html != "" && typeof notification.html != "undefined"}
              {@html notification.html}
            {/if}
          </Notification>
        </li>
      {/each}
    </ul>
  </section>
{/if}

<style lang="scss">
  section {
    position: fixed;
    top: calc(64px + 12px);
    right: 12px;
    z-index: 10000;
  }

  @media (min-width: 768px) {
    section {
      top: calc(64px + 24px);
      right: 24px;
    }
  }

  .u-gap-16 {
    gap: 1rem;
  }
</style>
